// default hero - image crops

.hero {
    --video-controls--top: 15px;
    display: block;
    position: relative;
    min-height: map-get($hero--img--min-heights, xs);
    max-height: map-get($hero--img--max-heights, xs);
    height: calc(#{map-get($hero--img--heights, xs)} - (var(--top-utility-bar--height) + var(--header--height)));
    overflow: hidden;
    // loop through each breakpoint
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            min-height: map-get($hero--img--min-heights, $breakpoint);
            max-height: map-get($hero--img--max-heights, $breakpoint);
            height: calc(#{map-get($hero--img--heights, $breakpoint)} - (var(--top-utility-bar--height) + var(--header--height)));
        }
    }
    &.hero--behind-header {
        --video-controls--top: calc(var(--header--height) + 15px);
        margin-top: calc(-1  * var(--header--height));
        min-height: calc(#{map-get($hero--img--min-heights, xs)} + var(--header--height));
        max-height: calc(#{map-get($hero--img--max-heights, xs)} + var(--header--height));
        height: calc(#{map-get($hero--img--heights, xs)} - (var(--top-utility-bar--height)));
        // loop through each breakpoint
        @each $breakpoint in map-keys($grid-breakpoints) {
            @include media-breakpoint-up($breakpoint) {
                min-height: calc(#{map-get($hero--img--min-heights, $breakpoint)} + var(--header--height));
                max-height: calc(#{map-get($hero--img--max-heights, $breakpoint)} + var(--header--height));
                height: calc(#{map-get($hero--img--heights, $breakpoint)} - (var(--top-utility-bar--height)));
            }
        }
    }
    .hero--image {
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% + 1px);
        background-color: $dark;
        overflow: hidden;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // fullscreen
    &.hero--fullscreen {
        min-height: none;
        max-height: none;
        height: max(#{map-get($hero--img--min-heights, xs)}, calc(var(--vpHeight) - (#{$hero--fullscreen--height-reduction--mobile} + var(--top-utility-bar--height) + var(--header--height))));
        @include media-breakpoint-up(sm) {
            height: max(#{map-get($hero--img--min-heights, sm)}, calc(var(--vpHeight) - (#{$hero--fullscreen--height-reduction--mobile} + var(--top-utility-bar--height) + var(--header--height))));
        }
        @include media-breakpoint-up(md) {
            height: max(#{map-get($hero--img--min-heights, md)}, calc(var(--vpHeight) - (#{$hero--fullscreen--height-reduction--mobile} + var(--top-utility-bar--height) + var(--header--height))));
        }
        @include media-breakpoint-up(lg) {
            height: max(#{map-get($hero--img--min-heights, lg)}, calc(var(--vpHeight) - (#{$hero--fullscreen--height-reduction--desktop} + var(--top-utility-bar--height) + var(--header--height))));
        }
        @include media-breakpoint-up(xl) {
            height: max(#{map-get($hero--img--min-heights, xl)}, calc(var(--vpHeight) - (#{$hero--fullscreen--height-reduction--desktop} + var(--top-utility-bar--height) + var(--header--height))));
        }
        @include media-breakpoint-up(xxl) {
            height: max(#{map-get($hero--img--min-heights, xxl)}, calc(var(--vpHeight) - (#{$hero--fullscreen--height-reduction--desktop} + var(--top-utility-bar--height) + var(--header--height))));
        }
    }
    &.hero--fullscreen.hero--behind-header {
        height: max(calc(#{map-get($hero--img--min-heights, xs)} + var(--header--height)), calc(var(--vpHeight) - (#{$hero--fullscreen--height-reduction--mobile} + var(--top-utility-bar--height))));
        @include media-breakpoint-up(sm) {
            height: max(calc(#{map-get($hero--img--min-heights, sm)} + var(--header--height)), calc(var(--vpHeight) - (#{$hero--fullscreen--height-reduction--mobile} + var(--top-utility-bar--height))));
        }
        @include media-breakpoint-up(md) {
            height: max(calc(#{map-get($hero--img--min-heights, md)} + var(--header--height)), calc(var(--vpHeight) - (#{$hero--fullscreen--height-reduction--mobile} + var(--top-utility-bar--height))));
        }
        @include media-breakpoint-up(lg) {
            height: max(calc(#{map-get($hero--img--min-heights, lg)} + var(--header--height)), calc(var(--vpHeight) - (#{$hero--fullscreen--height-reduction--desktop} + var(--top-utility-bar--height))));
        }
        @include media-breakpoint-up(xl) {
            height: max(calc(#{map-get($hero--img--min-heights, xl)} + var(--header--height)), calc(var(--vpHeight) - (#{$hero--fullscreen--height-reduction--desktop} + var(--top-utility-bar--height))));
        }
        @include media-breakpoint-up(xxl) {
            height: max(calc(#{map-get($hero--img--min-heights, xxl)} + var(--header--height)), calc(var(--vpHeight) - (#{$hero--fullscreen--height-reduction--desktop} + var(--top-utility-bar--height))));
        }
    }

    // carousel
    .carousel, .carousel-inner, .carousel-item {
        height: 100%;
        @include media-breakpoint-up(md) {
            .carousel-indicators {
                margin: 0;
                bottom: 99px;
                top: auto;
            }
        }
        @include media-breakpoint-up(lg) {
            .carousel-control-prev, .carousel-control-next {
                position: absolute;
                top: 50%;
                left: 0;
                width: 40px;
                height: 40px;
                margin-top: -20px;
                color: var(--primary);
                background-color: transparent;
                // backdrop-filter: blur(10px);
                opacity: 1;
                svg {
                    margin: 0;
                }
            }
            .carousel-control-next {
                left: auto;
                right: 0;
            }
        }
    }

    &.hero--overlapping-intro-section {
        .carousel-indicators {
            bottom: 0;
        }
        @include media-breakpoint-up(lg) {
            .carousel-indicators {
                bottom: calc(var(--header--height) - 1px);
            }
        }
    }

    // fullscreen
    &.hero--fullscreen {
        .carousel-indicators {
            bottom: 0;
        }
    }
    // fullscreen with content panels
    &.hero--fullscreen.hero--with-content {
        // common rules for all breakpoints
        .hero--layout {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-color: var(--main-content--top-section--bg-color);
            .hero--image {
                z-index: 1;
            }
            .hero--content {
                position: relative;
                z-index: 2;
                pointer-events: none;
                & > div {
                    pointer-events: all;
                }
            }
        }
        // mobile version has any-height panel below image spacer
        @include media-breakpoint-down(lg) {
            --padding-for-visual: 75vw;
            .carousel, .carousel-inner, .carousel-item {
                height: auto;
            }
            height: auto;
            .hero--layout {
                padding-top: var(--padding-for-visual);
                .hero--image {
                    height: calc(var(--padding-for-visual) + var(--header--height));
                }
                .hero--content {
                    .panel {
                        background-color: var(--hero--mobile--panel--bg-color);
                        min-height: var(--header--height);
                    }
                }
            }
            .carousel-indicators {
                margin: 0;
                bottom: auto;
                top: calc(var(--padding-for-visual) + 1px);
                transform: translateY(-100%);
            }
            &.hero--behind-header {
                .hero--layout {
                    padding-top: calc(var(--header--height) + var(--padding-for-visual));
                    .hero--image {
                        height: calc(var(--header--height) + var(--padding-for-visual) + 60px);
                    }
                }
                .carousel-indicators {
                    top: calc(var(--header--height) + var(--padding-for-visual) + 1px);
                }
            }
            @include media-breakpoint-up(sm) {
                --padding-for-visual: 50vw;
            }
            @include media-breakpoint-up(md) {
                --padding-for-visual: 40vw;
            }
        }
        // desktop version has panel floating over image
        @include media-breakpoint-up(lg) {
            .hero--layout {
                height: 100%;
                .hero--content {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .panel {
                        background-color: var(--hero--desktop--panel--bg-color);
                        backdrop-filter: blur(10px);
                    }
                }
            }
            &.hero--behind-header {
                .hero--layout {
                    .hero--content {
                        padding: var(--header--height) 0 0;
                    }
                }
            }
        }
        // with allowance for overlapping-intro-section
        &.hero--overlapping-intro-section {
            @include media-breakpoint-up(lg) {
                .carousel-indicators {
                    bottom: calc(var(--header--height) - 1px);
                }
                &.hero--behind-header {
                    .hero--layout {
                        .hero--content {
                            padding: var(--header--height) 0;
                        }
                    }
                }
            }
        }
    }

    // Hero video stuff

    .hero-layout .hero--video {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        &.vimeo > iframe { // bug fix for vimeo embed
            display: none;
        }
        .hero--video-inner { // should remain as 16:9 ratio container and resize to emulate object-fit: cover
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            opacity: 0;
            transition: opacity 1s linear;
            &.reveal {
                opacity: 1;
            }
            iframe {
                position: relative;
                z-index: 2;
                width: 100%;
                height: 100%;
                opacity: 1;
                transition: opacity 200ms linear;
            }
        }
        .hero--video--control {
            display: flex;
            flex-direction: row;
            gap: 4px;
            position: absolute;
            z-index: 2;
            top: var(--video-controls--top);
            right: 15px;
            button {
                display: flex;
                justify-content: center;
                align-items: center;
                appearance: none;
                width: 30px;
                height: 30px;
                font-size: 14px;
                line-height: 14px;
                margin: 0;
                padding: 0;
                border: 0;
                background-color: rgba(black, .4);
                color: white;
                cursor: pointer;
                &:not(:disabled):focus, &:not(:disabled):hover {
                    background-color: var(--dark);
                    color: white;
                }
                .hero--video--control--play, .hero--video--control--pause, .hero--video--control--sound-on, .hero--video--control--sound-off {
                    display: inline;
                    &[aria-hidden="true"] {
                        display: none;
                    }
                }
            }
        }
    }
}
