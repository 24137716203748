#header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 10;
    height: 0;
    pointer-events: none;

    header {
        position: relative;
        height: calc(var(--top-utility-bar--height) + var(--header--height));
        transition: transform 300ms ease-in-out;
        will-change: transform;

        .header-hidden:not(.mobile-menu-active) & {
            transform: translate3d(0,calc(-1 * (var(--top-utility-bar--height) + var(--header--height))),0);
        }

        &:before {
            display: block;
            content: '';
            position: absolute;
            z-index: -1;
            top: var(--top-utility-bar--height);
            left: 0;
            width: 100%;
            height: var(--header--height);
            background-color: var(--header--bg-color);
            transition: background-color 300ms linear;
            will-change: background-color;
            backdrop-filter: blur(5px);
        }

        .page-scrolled &:before {
            background-color: var(--header--bg-color--solid);
        }

        #skip-nav {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: var(--top-utility-bar--height);
            left: 0;
            z-index: 2;
            width: 100%;
            height: var(--header--height);
            background-color: white;
            font-size: 1rem;
            line-height: 1rem;
            text-decoration: underline;
            opacity: 0;
            pointer-events: none;
            transition: opacity 200ms linear;

            &:focus {
                opacity: 1;
                pointer-events: all;
            }
        }

        .header--utility-bar {
            // links
            --link--color: #{$admc--light-grey};
            --link--color--hover: white;
            background-color: var(--header--utility-bar--bg-color);
            width: 100%;
            height: var(--top-utility-bar--height);
            pointer-events: all;

            @include media-breakpoint-down(xxl) {
                & > .container-fluid {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
            }

            .header--utility-bar--inner {
                height: var(--top-utility-bar--height);

                .header--utility-bar--centre-picker {
                    .dropdown-toggle {
                        height: var(--top-utility-bar--height);
                        font-size: var(--header--utility-bar--font-size);
                        line-height: var(--header--utility-bar--font-size);
                        text-transform: uppercase;
                        background-color: var(--header--centre-picker-toggle--bg-color);
                        border-color: var(--header--centre-picker-toggle--bg-color);

                        &:not(:disabled):not(.disabled) {
                            &:hover, &:focus {
                                background-color: var(--header--centre-picker-toggle--bg-color--hover);
                                border-color: var(--header--centre-picker-toggle--bg-color--hover);
                                color: white;
                            }
                        }
                    }
                }

                .header--utility-bar--links {
                    display: flex;
                    // margin-right: 0.75rem;
                    // justify-content: flex-end;

                    li {
                        margin: 0 !important;

                        .btn {
                            font-size: var(--header--utility-bar--font-size);
                            line-height: var(--header--utility-bar--font-size);
                            text-transform: uppercase;
                        }
                    }
                }

                .header--utility-bar--membership .btn {
                    display: inline-flex;
                    align-items: center;
                    height: var(--top-utility-bar--height);
                    font-size: var(--header--utility-bar--font-size);
                    line-height: var(--header--utility-bar--font-size);
                    text-transform: uppercase;
                }
            }
        }

        .header--content {
            display: flex;
            width: 100%;
            height: var(--header--height);
            justify-content: flex-end;
            align-items: center;
            gap: 15px;
            pointer-events: all;

            .header--logo {
                flex: 0 1 auto;
                width: 100%;
                max-width: var(--header--logo--max-width);
                margin-right: auto;

                a {
                    display: block;
                    width: fit-content;

                    img {
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: var(--header--logo--max-height);
                        object-fit: contain;
                        object-position: left center;
                    }
                }
            }

            .header--feature-menu {
                // links
                --link--color: #{$admc--light-grey};
                --link--color--hover: white;
                display: flex;
                flex: 1 0 auto;
                justify-content: flex-end;
                height: 100%;

                & > ul {
                    margin: 0 !important;
                    height: 100%;

                    & > li {
                        display: inline-flex;
                        align-items: center;
                        margin: 0 !important;
                        height: 100%;

                        & > .btn {
                            text-transform: uppercase;
                        }

                        & > .header--feature-menu--dropdown {
                            height: 100%;
                            position: relative;
                            z-index: 2;

                            .header--feature-menu--dropdown--toggle {
                                text-transform: uppercase;
                                height: 100%;
                                margin-bottom: .25rem;
                                &:before {
                                    display: block;
                                    content: '';
                                    position: absolute;
                                    z-index: -1;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    background-color: rgba(white, 0);
                                    transition: background-color 250ms linear, color 250ms linear;
                                }

                                svg {
                                    position: absolute;
                                    left: 50%;
                                    bottom: 30px;
                                    color: white;
                                    opacity: 0;
                                    transform: translate3d(-50%, -5px, 0);
                                    will-change: transform;
                                    transition: opacity 250ms linear, transform 250ms ease-in-out;
                                    pointer-events: none;
                                    user-select: none;
                                }

                                &:hover, &:focus {
                                    svg {
                                        opacity: 1;
                                        transform: translate3d(-50%, 0, 0);
                                    }
                                }

                                &[aria-expanded="true"] {
                                    background-color: transparent;
                                    color: white;
                                }
                            }

                            .header--feature-menu--dropdown--content {
                                display: none;
                                opacity: 0;
                                transition: opacity 250ms linear;
                                will-change: opacity;

                                &[aria-hidden="false"] {
                                    display: block;
                                    position: fixed;
                                    top: calc(var(--top-utility-bar--height) + var(--header--height));
                                    left: 0;
                                    width: 100%;
                                    max-height: calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height)));
                                    overflow-y: auto;
                                    background-color: var(--feature-menu--bg-color);
                                    box-shadow: 0 2px 2px rgba(black, 0.2);
                                    padding: 40px 0;

                                    &.show {
                                        opacity: 1;
                                    }
                                }

                                .header--feature-menu--dropdown--listing {
                                    display: flex;
                                    flex-wrap: wrap;
                                    gap: 20px;

                                    li {
                                        width: calc((100% - 60px) / 4);

                                        a {
                                            display: block;
                                            position: relative;

                                            img {
                                                aspect-ratio: 4/3;
                                            }

                                            p {
                                                display: flex;
                                                justify-content: space-between;
                                                gap: 5px;
                                                align-items: center;
                                                position: absolute;
                                                z-index: 2;
                                                bottom: 5px;
                                                left: 0;
                                                width: 100%;
                                                min-height: 50px;
                                                margin: 0;
                                                padding: 6px 10px;
                                                font-size: 0.875rem;
                                                line-height: 1.3;
                                                text-transform: uppercase;
                                                background-color: $admc--darkest-green;
                                                color: white;
                                                transform-origin: 0 100%;
                                                transition: background-color 250ms linear, color 250ms linear;
                                            }

                                            &:hover, &:focus {
                                                p {
                                                    background-color: $admc--dark-green;
                                                    color: white;
                                                    text-decoration: underline;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .header--desktop-search--toggle {
                // links
                --link--color: #{$admc--light-grey};
                --link--color--hover: white;
            }

            .header--menu-toggle {
                // links
                --link--color: #{$admc--light-grey};
                --link--color--hover: white;

                button {
                    display: flex;
                    gap: 5px;
                    flex-direction: column;
                    align-items: center;
                    text-transform: uppercase;

                    .bars {
                        display: block;
                        width: 23px;
                        height: 23px;
                        position: relative;
                        transition: transform 200ms ease-in-out;
                        will-change: transform;

                        i {
                            position: absolute;
                            top: 4px;
                            left: 0;
                            width: 23px;
                            height: 2px;
                            border-radius: 1px;
                            background-color: var(--link--color);
                            transition: transform 200ms ease-in-out, width 200ms ease-in-out, opacity 200ms ease-in-out;
                            will-change: transform;

                            &:nth-child(1) {
                                transform-origin: left top;
                            }

                            &:nth-child(2) {
                                transform-origin: center center;
                                top: 11px;
                            }

                            &:nth-child(3) {
                                transform-origin: left bottom;
                                top: 18px;
                            }
                        }
                    }

                    @include media-breakpoint-down(lg) {
                        .header--menu-toggle--label {
                            font-size: 12px;
                            line-height: 1;
                        }
                    }

                    &:hover, &:focus {
                        .bars i {
                            background-color: var(--link--color--hover);
                        }
                    }

                    &[aria-expanded="true"] {
                        .bars {
                            transform: rotate(-90deg) translate3d(0,-0.5px,0);

                            i {
                                &:nth-child(1) {
                                    transform: translate3d(5px,-1px,0) rotateZ(45deg);
                                }

                                &:nth-child(2) {
                                    transform: scaleX(0);
                                }

                                &:nth-child(3) {
                                    transform: translate3d(5px,1px,0) rotateZ(-45deg);
                                }
                            }
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        flex-direction: row;
                        gap: 8px;
                        margin-bottom: 0.15em;
                        padding-right: 0;

                        .header--menu-toggle--label {
                            transform: var(--header--menu-toggle--desktop-label-transform);
                        }
                    }
                }
            }
        }
    }

    #header--desktop-search--wrapper {
        // links
        --link--color: #{$admc--light-grey};
        --link--color--hover: white;
        display: none;
        pointer-events: none;

        @include media-breakpoint-up(lg) {
            &[aria-hidden="false"] {
                display: block;
                position: absolute;
                top: var(--top-utility-bar--height);
                left: 0;
                z-index: 2;
                width: 100%;
                height: var(--header--height);
                background-color: var(--header--bg-color--solid);
                text-align: center;
                opacity: 0;
                transition: opacity 200ms linear;

                &.search-form--active {
                    opacity: 1;
                    pointer-events: all;
                }

                #header--desktop-search--form {
                    display: flex;
                    margin: 0 auto;
                    align-items: center;
                    height: var(--header--height);
                    padding: 0 $container-padding-x;
                    max-width: 400px;

                    input[type="search"] {
                        flex: 1 1 auto;
                    }

                    button[type="submit"] {
                        flex: 0 0 auto;
                        margin-left: 10px;
                    }
                }

                #header--desktop-search--close-btn {
                    position: absolute;
                    top: 50%;
                    right: $container-padding-x;
                    transform: translateY(-50%);
                    font-size: 23px;
                }
            }
        }
    }
}
