.carousel .carousel-indicators {
    margin-bottom: 0;
    &.active {
        color: white;
    }
    li {
        display: inline-flex;
        width: 30px;
        height: 40px;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: var(--primary);
        opacity: 1;
        margin: 0;
        border: 0;
        &.active {
            color: white;
        }
        &[data-bs-target] {
            border: 0;
        }
        &:not(.carousel-control) {
            &:before {
                display: block;
                content: '';
                width: 12px;
                height: 12px;
                border-radius: 6px;
                background-color: var(--primary);
                opacity: 0.6;
                transform: scale(0.6);
                will-change: transform;
                transition: opacity 600ms linear, transform 600ms ease-in-out;
            }
            &:hover, &.focus, &.active {
                &:before {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
        &.carousel-control {
            width: 40px;
            svg {
                opacity: 0.6;
                will-change: opacity;
                transition: opacity 250ms linear;
            }
            &:hover, &.focus {
                svg {
                    opacity: 1;
                }
            }
        }
    }
    @include media-breakpoint-up(xxl) {
        li {
            &:nth-child(1) {
                width: 35px;
                padding-left: 5px;
            }
            &:nth-last-child(1) {
                width: 35px;
                padding-right: 5px;
            }
        }
        .hero & {
            li {
                &:nth-child(1) {
                    width: 30px;
                    padding-left: 0;
                }
                &:nth-last-child(1) {
                    width: 30px;
                    padding-right: 0;
                }
                &:nth-child(2) {
                    width: 35px;
                    padding-left: 5px;
                }
                &:nth-last-child(2) {
                    width: 35px;
                    padding-right: 5px;
                }
            }
        }
    }
}
