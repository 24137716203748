@mixin non-retina-font-smoothing-antialiased {
    @media(-webkit-min-device-pixel-ratio: 1) {
        -webkit-font-smoothing: antialiased;
    }
}

// theme mixins

@mixin on-light-theme-vars {
    // basic
    --theme--color: #{$dark};
    color: var(--theme--color);
    // for headings, quotes etc
    --theme--color--emphasis: #{$brand--turquoise--mid};
    --theme--color--emphasis--invert: white;
    // for minor text, helper text etc
    --theme--color--subtle: #{$text-muted};
    // links
    --theme--link--color: #{$link-color};
    --theme--link--text-decoration: #{$link-decoration};
    --theme--link--color--hover: #{$link-hover-color};
    --theme--link--text-decoration--hover: #{$link-hover-decoration};
    // icons, bullets, rules etc
    --theme--decor--color: #{$brand--peach--mid};
    --theme--rule--color: #{$brand--grey--light};
    // button - standard
    --theme--btn--bg-color: #{$brand--yellow--light};
    --theme--btn--border-color: #{$brand--yellow--light};
    --theme--btn--color: #{$dark};
    --theme--btn--bg-color--hover: #{$brand--peach--mid};
    --theme--btn--border-color--hover: #{$brand--peach--mid};
    --theme--btn--color--hover: white;
    // button - primary
    --theme--btn--primary--bg-color: #{$brand--yellow--light};
    --theme--btn--primary--border-color: #{$brand--yellow--light};
    --theme--btn--primary--color: #{$dark};
    --theme--btn--primary--bg-color--hover: #{$brand--peach--mid};
    --theme--btn--primary--border-color--hover: #{$brand--peach--mid};
    --theme--btn--primary--color--hover: white;
    // button - secondary
    --theme--btn--secondary--bg-color: #{$brand--turquoise--mid};
    --theme--btn--secondary--border-color: #{$brand--turquoise--mid};
    --theme--btn--secondary--color: white;
    --theme--btn--secondary--bg-color--hover: #{$brand--peach--mid};
    --theme--btn--secondary--border-color--hover: #{$brand--peach--mid};
    --theme--btn--secondary--color--hover: white;
}

@mixin on-dark-theme-vars {
    // basic
    --theme--color: #{$brand--grey--lighter};
    color: var(--theme--color);
    // for headings, quotes etc
    --theme--color--emphasis: white;
    --theme--color--emphasis--invert: #{$brand--peach--mid};
    // for minor text, helper text etc
    --theme--color--subtle: #{$brand--grey--light};
    // links
    --theme--link--color: white;
    --theme--link--text-decoration: #{$link-decoration};
    --theme--link--color--hover: white;
    --theme--link--text-decoration--hover: #{$link-hover-decoration};
    // icons, bullets, rules etc
    --theme--decor--color: #{$brand--yellow--light};
    --theme--rule--color: #{rgba(white, 0.4)};
    // button - standard
    --theme--btn--bg-color: #{$brand--yellow--light};
    --theme--btn--bg-color--hover: #{$brand--peach--mid};
    --theme--btn--color--hover: white;
    // button - primary
    --theme--btn--primary--bg-color: #{$brand--yellow--light};
    --theme--btn--primary--border-color: #{$brand--yellow--light};
    --theme--btn--primary--color: black;
    --theme--btn--primary--bg-color--hover: #{$brand--peach--mid};
    --theme--btn--primary--border-color--hover: #{$brand--peach--mid};
    --theme--btn--primary--color--hover: white;
    // button - secondary
    --theme--btn--secondary--bg-color: #{$brand--turquoise--light};
    --theme--btn--secondary--border-color: #{$brand--turquoise--light};
    --theme--btn--secondary--color: black;
    --theme--btn--secondary--bg-color--hover: #{$brand--peach--mid};
    --theme--btn--secondary--border-color--hover: #{$brand--peach--mid};
    --theme--btn--secondary--color--hover: white;
}


