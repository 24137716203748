.panel {
    padding: $container-padding-x; // default-padding
    background-color: var(--panel--bg-color);
    color: var(--panel--color);
    & > *:last-child, & > *[data-hm-section]:last-child > *:last-child {
        margin-bottom: 0;
    }

    // &.hero-panel-rounded {
    //     border-radius: 50px 50px 0 0;
    //     @include media-breakpoint-up(lg) {
    //         border-radius: 0 0 0 0;
    //     }
    // }

    &.panel-rounded {
        border-radius: 0 0 25px 25px;
        @include media-breakpoint-up(md) {
            border-radius: 0 0 50px 50px;
        }
        @include media-breakpoint-up(lg) {
            border-radius: 100px;
            padding: 3rem 100px !important;
        }
    }

    &.panel--over-image {
        --color--emphasis: var(--panel--over-image--color);
        background-color: var(--panel--over-image--bg-color);
        color: var(--panel--over-image--color);
        backdrop-filter: blur(5px);
    }

    &.panel--white {
        --panel--bg-color: white;
        background-color: white;
    }

    &.panel--light-green {
        --color:                var(--dark);
        --primary:              #{$admc--primary};
        --primary--hover:       #{$admc--primary-hover};
        --secondary:            #{$admc--secondary};
        --secondary--hover:     #{$admc--secondary-hover};
    
        --decor--color:         white;
        --rule--color:          #{$gray-400};
        --color--emphasis:      white;
        --color--subtle:        rgba(255,255,255,0.6);
    
        --link--color:                   white;
        --link--text-decoration:         underline;
        --link--color--hover:            white;
    
        background-color: var(--light);
        color: var(--color);
        a:not(.btn, .dropdown-item, .page-link, .visual-listing--item), .btn.btn-link {
            text-decoration-color: rgba(255,255,255,0.4);
            &:hover, &:focus {
                text-decoration-color: var(--link--color--hover);
            }
        }
    }

    &.panel--dark-green {
        --color:                var(--light);
        --primary:              #{$admc--primary};
        --primary--hover:       #{$admc--primary-hover};
        --secondary:            #{$admc--secondary};
        --secondary--hover:     #{$admc--secondary-hover};
    
        --decor--color:         white;
        --rule--color:          #{$gray-400};
        --color--emphasis:      white;
        --color--subtle:        rgba(255,255,255,0.6);
    
        --link--color:                   white;
        --link--text-decoration:         underline;
        --link--color--hover:            white;
    
        background-color: var(--panel--dark--background-color);
        color: var(--color);
        a:not(.btn, .dropdown-item, .page-link, .visual-listing--item), .btn.btn-link {
            text-decoration-color: rgba(255,255,255,0.4);
            &:hover, &:focus {
                text-decoration-color: var(--link--color--hover);
            }
        }
    }
    
    &.wide-image-text-panel {
        display: block;
        .wide-image-text-panel--image {
            display: block;
            margin-bottom: 15px;
        }
        .wide-image-text-panel--content {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            min-height: 100%;
            justify-content: center;
            align-items: flex-start;
            .wide-image-text-panel--content--inner {
                & > *:last-child {
                    margin-bottom: 0;
                }
                ul.taglist {
                    margin: 0 0 10px;
                    justify-content: flex-end;
                    @include media-breakpoint-up(md) {
                        float: right;
                        margin: 0 0 15px 15px;
                        max-width: 50%;
                    }
                    @include media-breakpoint-up(lg) {
                        max-width: 33%;
                    }
                    @include media-breakpoint-up(xl) {
                        max-width: 25%;
                    }
                }
            }
        }
        @include media-breakpoint-up(md) {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            .wide-image-text-panel--image {
                position: relative;
                flex: 0 0 auto;
                min-height: 100%;
                width: 50%;
                max-width: 300px;
                margin: 0;
                &:before { // minimum ratio 16:9
                    display: block;
                    content: '';
                    width: 100%;
                    height: 0;
                    padding-bottom: 56.25%;
                }
                .img-scale-wrapper, img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .wide-image-text-panel--content {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                min-height: 100%;
                justify-content: center;
                align-items: flex-start;
                .wide-image-text-panel--content--inner {
                    width: 100%;
                }

            }
            // image right
            &.wide-image-text-panel--image-right {
                .wide-image-text-panel--image {
                    order: 2;
                }
                .wide-image-text-panel--content {
                    order: 1;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            .wide-image-text-panel--image {
                max-width: 400px;
            }
        }
    }
    .panel--heading-icon {
        color: var(--panel--heading-icon--color);
        font-size: var(--panel--heading-icon--font-size);
        line-height: 0;
    }
}

.map-panel {
    width: 100%;

    .map-mask {
        position: relative;
        width: 100%;
        height: 0;
        padding: 0 0 100%;
        overflow: hidden;
        z-index: 0;

        @include media-breakpoint-up(md) {
            padding-bottom: 56.25%;
        }

        iframe, picture, img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-width: none;
            z-index: 0;
            border: 0;
        }
    }
}

// split-image-bg-sections 

.split-image-bg-sections {
    --gap: 30px;
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    .split-image-bg-section {
        display: flex;
        position: relative;
        width: 100%;
        min-height: var(--vpWidth);
        justify-content: center;
        align-items: center;
        padding: var(--edge-padding);
        &--img {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            aspect-ratio: none;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &--content {
            position: relative;
            z-index: 2;
            width: min(500px, 100%);
            opacity: 1;
            transform: scale(0.9);
            transform-origin: center center;
            will-change: transform;
            transition: opacity 500ms linear, transform 1s ease-out;
            transition-delay: 1s;
        }
        &.hunt-in .split-image-bg-section--content {
            opacity: 1;
            transform: scale(1);
        }
    }
    @include media-breakpoint-up(sm) {
        .split-image-bg-section {
            min-height: calc(var(--vpWidth) * .75);
        }
    }
    @include media-breakpoint-up(md) {
        .split-image-bg-section {
            min-height: calc(var(--vpWidth) * .5625);
        }
    }
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        min-height: calc((var(--vpWidth) * .5625) / 2);
        .split-image-bg-section {
            width: calc(50% - 15px);
            min-height: 100%;
            justify-content: flex-start;
            &--content {
                width: min(500px, 75%);
                transform-origin: left center;
            }
            &:nth-child(2) {
                justify-content: flex-end;
                .split-image-bg-section--content {
                    transform-origin: right center;
                }
            }
        }
    }
}

// Panel behind widescreen (min-ratio) media element

.panel-img-overlap {
    --img-width: 50%;
    --content-width: 50%;
    --overlap: 120px;
    @include media-breakpoint-up(lg) {
        .panel-img-overlap--inner {
            display: flex;
            width: 100%;
            align-items: center;
            .panel-img-overlap--visual {
                position: relative;
                z-index: 2;
                width: calc(var(--img-width) + var(--overlap));
                .panel-img-overlap--visual--inner {
                    background-color: $dark;
                }
            }
            .panel-img-overlap--content {
                position: relative;
                z-index: 1;
                width: var(--content-width);
                align-self: stretch;
                .panel {
                    display: flex;
                    flex-direction: column;
                    min-height: 100%;
                    justify-content: center;
                }
            }
        }
        &:not(.panel-img-overlap--img-left) {
            .panel-img-overlap--visual {
                order: 2;
                margin-left: calc(-1 * var(--overlap));
            }
            .panel-img-overlap--content {
                order: 1;
                .panel {
                    padding-right: calc(var(--overlap) + 30px) !important;
                }
            }
        }
        &--img-left {
            .panel-img-overlap--visual {
                order: 1;
                margin-right: calc(-1 * var(--overlap));
            }
            .panel-img-overlap--content {
                order: 2;
                .panel {
                    padding-left: calc(var(--overlap) + 30px) !important;
                }
            }
        }
    }
}

// Widescreen img - content panel overlap -->

.widescreen-image-panel-overlap {
    --img-width: 640px;
    --content-width: 376px;
    &--img {
        background-color: $dark;
        img {
            aspect-ratio: 16/9;
        }
    }
    @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        min-height: calc(var(--img-width) * .5625);
        padding: 48px 0;
        &--img {
            display: block;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: var(--img-width);
            height: 100%;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &--content {
            position: relative;
            z-index: 2;
            width: var(--content-width);
        }
        &.widescreen-image-panel-overlap--img-right {
            justify-content: flex-start;
            .widescreen-image-panel-overlap--img {
                left: auto;
                right: 0;
            }
        }
    }
    @include media-breakpoint-up(xl) {
        --img-width: 736px;
        --content-width: 451px;
    }
    @include media-breakpoint-up(xxl) {
        --img-width: 856px;
        --content-width: 526px;
    }
}
