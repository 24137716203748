﻿// ********************
// **  Site colours  **
// ********************

$admc--darkest-green:       #042e32;
$admc--dark-green:          #005455;
$admc--light-green:         #2db686;
$admc--light-grey:          #d9e6e6;
$admc--dark-grey:           #d9e6e6;
$admc--red:                 #a61a21;

$admc--primary:             $admc--light-green;
$admc--primary-hover:       darken($admc--primary, 8%);
$admc--secondary:           $admc--dark-green;
$admc--secondary-hover:     $admc--primary-hover;

// Misc colours

$color--light-grey: 	rgb(233,234,236);
$color--dark-grey: 	    rgb(91,103,112);
$color--darkest-grey: 	rgb(45,41,38);

// set fundamental bootstrap colour variables

$primary:                       $admc--primary;
$secondary:                     $admc--secondary;
$light:                         $admc--light-grey;
$dark:                          $color--darkest-grey;

$body-bg:                       white;
$body-color:                    $dark;

$link-color:                    $admc--primary;
$link-decoration:               none;
$link-hover-color:              $admc--primary-hover;
$link-hover-decoration:         underline;

// Hero area
$hero--fullscreen--height-reduction--mobile:             30px;
$hero--fullscreen--height-reduction--desktop:            30px;
$hero--img--min-heights: (
    xs: 75vw,
    sm: 75vw,
    md: 300px,
    lg: 350px,
    xl: 400px,
    xxl: 500px
);
$hero--img--heights: (
    xs: 75vw,
    sm: 75vw,
    md: calc(100vh - 200px),
    lg: calc(100vh - 200px),
    xl: calc(100vh - 200px),
    xxl: calc(100vh - 200px)
);
$hero--img--max-heights: (
    xs: 75vw,
    sm: 75vw,
    md: 450px,
    lg: 500px,
    xl: 550px,
    xxl: 600px
);

// 50/50 split layouts

$split-img-content--content--margin--lg:    30px;
$split-img-content--content--margin--xl:    30px;
$split-img-content--content--margin--xxl:   30px;

// Image galleries

$image-gallery--small-thumb-height: 120px;
$image-gallery--medium-thumb-height: 180px;
$image-gallery--large-thumb-height: 240px;
$image-gallery--thumb--margin: 5px;




// ******************************************************************
// **                                                              **
// **   BOOTSTRAP VARIABLES - (overriding bootstrap defaults)      **
// **                                                              **
// ******************************************************************

$component-active-bg:           $primary;
$component-active-color:        white !important;

// Fonts

$font-family-base:             var(--body--font-family);
$font-strong:                  var(--strong--font-family);

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-weight-base:            var(--body--font-weight);
$line-height-base:            1.5;

$lead-font-size:              $font-size-base * 1.05;
$lead-font-weight:            400;

$paragraph-margin-bottom:     1.25rem;

$headings-font-family:        var(--headings--font-family);
$headings-font-weight:        var(--headings--font-weight);
$headings-line-height:        1.2;

$h1-font-size:                $font-size-base * 2;
$h2-font-size:                $font-size-base * 1.7;
$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.3;
$h5-font-size:                $font-size-base * 1.1;
$h6-font-size:                $font-size-base;
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
);

$display-font-sizes: (
  1: 3.4rem,
  2: 3rem,
  3: 2.8rem,
  4: 2.6rem,
  5: 2.4rem,
  6: 2.4rem
);

// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.5,
        ),
        5: (
            $spacer * 3,
        ),
        // added
        6: (
            $spacer * 4,
        ),
        // added
        7:
            (
                $spacer * 5,
            ),
        // added
        8:
            (
                $spacer * 6,
            )
            // added,
    ),
    $spacers
);

// Forms + Buttons

// $input-btn-padding-y:         .65rem;
// $input-btn-padding-x:         1.15rem;
// $input-btn-padding-y-sm:      .25rem;
// $input-btn-padding-x-sm:      .5rem;
// $input-btn-padding-y-lg:      0.95rem;
// $input-btn-padding-x-lg:      1.5rem;

$input-btn-padding-y:         .50rem;
$input-btn-padding-x:         1.00rem;
$input-btn-padding-y-sm:      $input-btn-padding-y;
$input-btn-padding-x-sm:      $input-btn-padding-x;
$input-btn-padding-y-lg:      $input-btn-padding-y;
$input-btn-padding-x-lg:      $input-btn-padding-x;

$border-radius:                   0;
$border-radius-lg:                0;
$border-radius-sm:                0;

$btn-border-radius:           3rem;
$btn-border-radius-sm:        $btn-border-radius;
$btn-border-radius-lg:        $btn-border-radius;

$form-select-border-radius:       $btn-border-radius;
$form-select-border-radius-sm:    $btn-border-radius;
$form-select-border-radius-lg:    $btn-border-radius;

$form-check-input-border-radius:    0;
$form-check-input-checked-color:    white;

// Pagination

$pagination-border-width:           0;
$pagination-border-radius:          0;
